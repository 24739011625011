<template>
  <div v-if="menu" class="col col-lg-auto d-print-none">
    <aside :class="['navbar-sidebar pb-lg-6', { collapsed }]">
      <div class="w-100">
        <div
          class="navbar navbar-expand-lg navbar-light p-0 flex-lg-column shadow-none"
        >
          <div class="w-100">
            <div
              :class="[
                `navbar-header py-2 py-md-3 ${collapsed ? 'px-3' : 'px-4'}`,
              ]"
            >
              <div class="row align-items-center">
                <div
                  :class="[
                    'col',
                    { 'd-flex justify-content-lg-center': collapsed },
                  ]"
                >
                  <user-avatar
                    v-if="collapsed"
                    size="lg"
                    :user="formatAvatarObject(menu.header.avatar)"
                  />

                  <div v-else>
                    <small>
                      {{ convertFunctionToValue(menu.header.subtitle) }}
                    </small>

                    <div>
                      <strong>
                        {{ convertFunctionToValue(menu.header.title) }}
                      </strong>
                    </div>
                  </div>
                </div>

                <div class="col-auto d-flex align-items-center">
                  <be-button
                    v-be-toggle.navbar-sidebar
                    variant="outline-secondary"
                    class="d-block d-lg-none pt-2"
                    :aria-label="$t('application.toggle_navigation')"
                    icon="fa-bars"
                    icon-style="far"
                    icon-custom-class="fa-xl"
                  />
                </div>
              </div>
            </div>
          </div>

          <be-collapse
            id="navbar-sidebar"
            class="navbar-collapse w-100"
            :visible="!isMobile"
          >
            <div class="w-100">
              <template
                v-for="(component, index) in menu.components"
                :key="index"
              >
                <component
                  :is="component.name"
                  v-bind="formatComponentProps(component.props)"
                  :collapsed="collapsed"
                />
              </template>

              <be-list-group tag="nav">
                <template v-for="(link, index) in menu.links" :key="index">
                  <navigation-link
                    :link="link"
                    :collapsed="collapsed"
                    :policies="config.policies"
                    :navbar-decorator="config['navbar-decorator']"
                  />
                </template>
              </be-list-group>

              <be-list-group
                tag="nav"
                class="d-none d-lg-block border border-top-1 border-right-0 border-bottom-0 border-left-0 rounded-0 mt-2"
              >
                <be-list-group-item
                  v-be-tooltip="{
                    title: $t('application.expand_menu'),
                    placement: 'right',
                    disabled: !collapsed,
                  }"
                  button
                  :class="[
                    'font-weight-normal',
                    { 'px-4 justify-content-center': collapsed },
                  ]"
                  @click="toggleSideNav(!collapsed)"
                >
                  <i
                    v-if="collapsed"
                    class="fa-fw fal fa-arrow-to-right mr-0"
                  />

                  <template v-else>
                    <i class="fa-fw fal fa-arrow-to-left" />
                    {{ $t("application.minimize_menu") }}
                  </template>
                </be-list-group-item>
              </be-list-group>
            </div>
          </be-collapse>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { computed } from "vue";
import { EventBus } from "@/event-bus";
import menuUtilities from "@/mixins/menuUtilities";
import sidebarMenus from "./menus/index";
import UpcomingMeetingsWrapper from "@/components/nav/UpcomingMeetingsWrapper.vue";

import NavigationLink from "./NavigationLink.vue";

const validateMenuType = (value) => Object.keys(sidebarMenus).includes(value);

export default {
  components: {
    NavigationLink,
    UpcomingMeetingsWrapper,
  },

  mixins: [menuUtilities],

  provide() {
    return {
      action: computed(() => this.action),
      controller: computed(() => this.controller),
    };
  },

  props: {
    action: {
      type: String,
      required: true,
    },

    controller: {
      type: String,
      required: true,
    },

    config: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isMobile: false,
    };
  },

  computed: {
    ...mapGetters({
      collapsed: "session/collapsedSideNav",
    }),

    menu() {
      return validateMenuType(this.menuType)
        ? sidebarMenus[this.menuType]
        : null;
    },

    menuType() {
      if (this.$currentAdminPanel.id) {
        return "admin_panel";
      } else if (this.$currentCompany.id) {
        return "board_room";
      } else {
        return "user_settings";
      }
    },
  },

  beforeMount() {
    if (window.innerWidth < 992) {
      this.isMobile = true;
    }
  },

  mounted() {
    // On mobile, expand the side nav by default
    // 992px is the breakpoint for the Bootstrap "lg" breakpoint
    if (window.innerWidth < 992) {
      this.expandSideNav();
    }

    // If the user has a preference for a collapsed side nav, then collapse it
    if (this.$currentUser.collapsed_side_nav) {
      this.collapseSideNav();
    }
  },

  methods: {
    ...mapActions({
      collapseSideNav: "session/collapseSideNav",
      expandSideNav: "session/expandSideNav",
      setPreferences: "current_user/setPreferences",
    }),

    async toggleSideNav(collapsed) {
      // Close all open tooltips, to avoid them being stuck open
      EventBus.emit("be::hide::tooltip");

      try {
        await this.setPreferences({ collapsed_side_nav: collapsed });

        if (this.$currentUser.collapsed_side_nav) {
          this.collapseSideNav();
        } else {
          this.expandSideNav();
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    formatAvatarObject(obj) {
      if (typeof obj === "function") {
        return obj(this);
      } else {
        return this.convertFunctionsToValues(obj);
      }
    },
  },
};
</script>
