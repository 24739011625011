/* eslint-disable ext/lines-between-object-properties */

import { itemActive } from "@/utils/menu";

export default {
  name: "Admin Panel",
  header: {
    title: (ctx) => ctx.$currentAdminPanel.title,
    subtitle: (ctx) => {
      return ctx.$t("layouts.admin_panel.sidebar.client_manager_for_w_type", {
        client_manager_type: ctx.$currentAdminPanel.client_manager_name,
      });
    },
    avatar: (ctx) => {
      return {
        name: ctx.$currentAdminPanel.title,
        image_url: ctx.$currentAdminPanel.logo_url,
      };
    },
  },

  links: [
    // Dashboard
    {
      text: (ctx) => ctx.$t("components.nav.board_room.dashboard"),
      url: "#",
      icon: "fa-browser",
      controller: "client_manager_dashboard",

      children: [
        {
          text: (ctx) => ctx.$t("layouts.admin_panel.sidebar.dashboard"),
          url: "/",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/admin_panel"],
            }),
        },
        {
          text: (ctx) => ctx.$t("layouts.admin_panel.sidebar.statistics"),
          url: "/dashboard/statistics/",
          controller: "admin_panels/dashboard/statistics",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/dashboard/statistics"],
            }),
          enabled: ({ policies }) => policies.can_show_overview_statistics,
        },
        {
          text: (ctx) => ctx.$t("layouts.admin_panel.sidebar.audit"),
          url: "/dashboard/audit/",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/dashboard/audits"],
            }),
          enabled: ({ policies }) => policies.can_show_overview_audit,
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.annual_report.other"),
          url: "/dashboard/annual_reports/",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/dashboard/annual_reports"],
            }),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.report.other"),
          url: "/dashboard/reports/",
          enabled: (ctx) => ctx.flipperFlag("admin_panel_reports_dashboard"),
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "admin_panels/dashboard/reports",
                "admin_panels/dashboard/corporate_group_reports",
              ],
            }),
        },
      ],
    },

    // Users
    {
      text: (ctx) => ctx.$t("activerecord.models.user.other"),
      icon: "fa-users",
      url: "/users",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["admin_panels/users"],
        }),
      enabled: ({ policies }) => policies.can_show_users,
    },

    // Organizations
    {
      text: (ctx) => ctx.$t("activerecord.models.company.other"),
      icon: "fa-building",
      url: "#",

      children: [
        {
          text: (ctx) =>
            ctx.$t("layouts.admin_panel.sidebar.all_organizations"),
          url: "/companies",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/companies/companies"],
            }),
        },
        {
          text: (ctx) =>
            ctx.$t("views.admin_panels.companies.board_members.show.title"),
          url: "/companies/board_members",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/companies/board_members"],
            }),
          enabled: ({ policies }) =>
            policies.can_show_organizations_board_members,
        },
        {
          text: (ctx) =>
            ctx.$t("views.admin_panels.companies.auditors.index.title"),
          url: "/companies/auditors",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/companies/auditors"],
            }),
          enabled: ({ policies }) => policies.can_show_organizations_auditors,
        },
        {
          text: (ctx) =>
            ctx.$t("views.admin_panels.companies.shareholders.show.title"),
          url: "companies/shareholders",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/companies/shareholders"],
            }),
          enabled: ({ policies }) =>
            policies.can_show_organizations_shareholders,
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.group.other"),
          url: "/companies/group",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "admin_panels/companies/group",
                "admin_panels/companies/group/invitations",
              ],
            }),
          enabled: (ctx) => ctx.$currentAdminPanel.integration !== "bank1",
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.sub_category.other"),
          url: "/companies/categories",
          controller: "admin_panels/companies/categories",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/companies/categories"],
            }),
          enabled: ({ policies }) => policies.can_edit_admin_panel && policies.can_show_organizations_categories,
        },
      ],
    },

    // Report templates
    {
      text: (ctx) => ctx.$t("activerecord.models.report_template.other"),
      icon: "fa-layer-group",
      url: "/reports/templates",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["admin_panels/reports/templates"],
        }),
      enabled: (ctx) =>
        ctx.policies.can_index_report_templates &&
        ctx.$currentAdminPanel.integration !== "bank1",
    },

    // Economy
    {
      text: (ctx) => ctx.$t("components.nav.board_room.financials"),
      icon: "fa-chart-line",
      url: "/financials",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: [
            "admin_panels/financials",
            "admin_panels/financials/widgets",
            "admin_panels/financials/tables",
            "admin_panels/financials/graphs",
            "admin_panels/financials/variables",
            "admin_panels/financials/data_variables",
            "admin_panels/financials/dashboards",
          ],
        }),
      enabled: (ctx) =>
        ctx.policies.can_show_financials &&
        ctx.$currentAdminPanel.integration !== "bank1",
    },

    // Tickets
    {
      text: (ctx) => ctx.$t("activerecord.models.ticket.other"),
      icon: "fa-inbox",
      url: "#",

      children: [
        {
          text: (ctx) => ctx.$t("models.ticket.all_tickets"),
          url: "/tickets",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/tickets"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_tickets && ctx.flipperFlag("collaborations"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.ticket_batch.other"),
          url: "/tickets/batches",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/tickets/batches"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_tickets && ctx.flipperFlag("collaborations"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.ticket_template.other"),
          url: "/tickets/templates",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/tickets/templates"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_tickets && ctx.flipperFlag("collaborations"),
        },
      ],
    },

    // Collaborations
    {
      text: (ctx) => ctx.$t("activerecord.models.collaboration.other"),
      icon: "fa-handshake",
      url: "/collaborations",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["admin_panels/collaborations"],
        }),
      enabled: (ctx) =>
        ctx.policies.can_index_collaborations &&
        ctx.flipperFlag("collaborations"),
    },

    // Documents
    {
      text: (ctx) => ctx.$t("activerecord.models.document.other"),
      icon: "fa-files",
      url: "#",

      children: [
        {
          text: (ctx) => ctx.$t("models.document.all_documents"),
          url: "/documents",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/documents"],
            }),
          enabled: (ctx) => ctx.policies.can_index_documents,
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.signature.other"),
          url: "/documents/signatures",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/documents/signatures"],
            }),
          enabled: (ctx) => ctx.policies.can_index_signatures,
        },
      ],
    },

    // Administration
    {
      text: (ctx) => ctx.$t("components.nav.board_room.administration"),
      url: "#",
      icon: "fa-users-cog",
      promoteSingleChildText: true,

      children: [
        {
          text: (ctx) => ctx.$t("models.admin_company.administrator.other"),
          url: "/administrators",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/administrators"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_memberships &&
            ctx.policies.can_edit_admin_panel,
        },
        {
          text: (ctx) => ctx.$t("admin_panels.admin.user_roles.index.title"),
          url: "admin/user_roles",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/admin/user_roles"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_show_user_roles &&
            ctx.policies.can_edit_admin_panel,
        },
        {
          text: (ctx) => ctx.$t("application.company_settings"),
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "admin_panels/admin/settings",
                "admin_panels/admin/settings/advanced_settings",
              ],
            }),
          url: "/admin/settings/edit",
          enabled: ({ policies }) => policies.can_edit_admin_panel,
        },
        {
          text: (ctx) =>
            ctx.$t("views.admin_panels.admin.partner_codes.index.title"),
          url: "/admin/partner_codes",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/admin/partner_codes"],
            }),
          enabled: (ctx) =>
            ctx.flipperFlag("admin_panel_partner_codes") &&
            ctx.policies.can_edit_admin_panel,
        },
        {
          text: (ctx) => ctx.$t("application.trash"),
          url: "/trash",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["admin_panels/trash"],
            }),
          enabled: ({ policies }) => policies.can_trash,
          promotedIcon: "fa-trash",
        },
      ],
    },
  ],
};
